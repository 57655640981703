import styles from './RelatedOrganizations.module.scss';
import { useTranslation } from 'react-i18next';
import MTLS from 'assets/img/logos/MTLSLogo.png';

export default function RelatedOrganizations() {
    const { t, i18n } = useTranslation();
    const locale = i18n.language || 'ar';

    return (
        <div className="container_max">
            <div className={styles.relatedTitle}>{t('Related Organizations')}</div>
            <div className={styles.relatedWrap}>
                <a
                    href={locale === "en" ? "https://www.mot.gov.sa/en" : "https://www.mot.gov.sa/ar"}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.relatedLink}>
                    <img src={MTLS} alt="MTLS" height={'24px'}
                        className={styles.relatedImage} />
                </a>
            </div>
        </div>
    );
}
